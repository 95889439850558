// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-360-jsx": () => import("./../../../src/pages/360.jsx" /* webpackChunkName: "component---src-pages-360-jsx" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-us-jsx": () => import("./../../../src/pages/about-us.jsx" /* webpackChunkName: "component---src-pages-about-us-jsx" */),
  "component---src-pages-cloud-jsx": () => import("./../../../src/pages/cloud.jsx" /* webpackChunkName: "component---src-pages-cloud-jsx" */),
  "component---src-pages-contact-jsx": () => import("./../../../src/pages/contact.jsx" /* webpackChunkName: "component---src-pages-contact-jsx" */),
  "component---src-pages-contact-us-danke-jsx": () => import("./../../../src/pages/contact-us/danke.jsx" /* webpackChunkName: "component---src-pages-contact-us-danke-jsx" */),
  "component---src-pages-contact-us-index-jsx": () => import("./../../../src/pages/contact-us/index.jsx" /* webpackChunkName: "component---src-pages-contact-us-index-jsx" */),
  "component---src-pages-digitalization-jsx": () => import("./../../../src/pages/digitalization.jsx" /* webpackChunkName: "component---src-pages-digitalization-jsx" */),
  "component---src-pages-en-360-jsx": () => import("./../../../src/pages/en/360.jsx" /* webpackChunkName: "component---src-pages-en-360-jsx" */),
  "component---src-pages-en-about-us-jsx": () => import("./../../../src/pages/en/about-us.jsx" /* webpackChunkName: "component---src-pages-en-about-us-jsx" */),
  "component---src-pages-en-cloud-jsx": () => import("./../../../src/pages/en/cloud.jsx" /* webpackChunkName: "component---src-pages-en-cloud-jsx" */),
  "component---src-pages-en-contact-jsx": () => import("./../../../src/pages/en/contact.jsx" /* webpackChunkName: "component---src-pages-en-contact-jsx" */),
  "component---src-pages-en-contact-us-danke-jsx": () => import("./../../../src/pages/en/contact-us/danke.jsx" /* webpackChunkName: "component---src-pages-en-contact-us-danke-jsx" */),
  "component---src-pages-en-contact-us-index-jsx": () => import("./../../../src/pages/en/contact-us/index.jsx" /* webpackChunkName: "component---src-pages-en-contact-us-index-jsx" */),
  "component---src-pages-en-digitalization-jsx": () => import("./../../../src/pages/en/digitalization.jsx" /* webpackChunkName: "component---src-pages-en-digitalization-jsx" */),
  "component---src-pages-en-index-jsx": () => import("./../../../src/pages/en/index.jsx" /* webpackChunkName: "component---src-pages-en-index-jsx" */),
  "component---src-pages-en-legal-notice-jsx": () => import("./../../../src/pages/en/legal-notice.jsx" /* webpackChunkName: "component---src-pages-en-legal-notice-jsx" */),
  "component---src-pages-en-machines-jsx": () => import("./../../../src/pages/en/machines.jsx" /* webpackChunkName: "component---src-pages-en-machines-jsx" */),
  "component---src-pages-en-mobile-app-jsx": () => import("./../../../src/pages/en/mobile-app.jsx" /* webpackChunkName: "component---src-pages-en-mobile-app-jsx" */),
  "component---src-pages-en-privacy-jsx": () => import("./../../../src/pages/en/privacy.jsx" /* webpackChunkName: "component---src-pages-en-privacy-jsx" */),
  "component---src-pages-en-refillment-jsx": () => import("./../../../src/pages/en/refillment.jsx" /* webpackChunkName: "component---src-pages-en-refillment-jsx" */),
  "component---src-pages-en-testimonials-jsx": () => import("./../../../src/pages/en/testimonials.jsx" /* webpackChunkName: "component---src-pages-en-testimonials-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-legal-notice-jsx": () => import("./../../../src/pages/legal-notice.jsx" /* webpackChunkName: "component---src-pages-legal-notice-jsx" */),
  "component---src-pages-machines-jsx": () => import("./../../../src/pages/machines.jsx" /* webpackChunkName: "component---src-pages-machines-jsx" */),
  "component---src-pages-mobile-app-jsx": () => import("./../../../src/pages/mobile-app.jsx" /* webpackChunkName: "component---src-pages-mobile-app-jsx" */),
  "component---src-pages-privacy-jsx": () => import("./../../../src/pages/privacy.jsx" /* webpackChunkName: "component---src-pages-privacy-jsx" */),
  "component---src-pages-refillment-jsx": () => import("./../../../src/pages/refillment.jsx" /* webpackChunkName: "component---src-pages-refillment-jsx" */),
  "component---src-pages-testimonials-jsx": () => import("./../../../src/pages/testimonials.jsx" /* webpackChunkName: "component---src-pages-testimonials-jsx" */)
}

